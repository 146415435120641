import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from './HeaderSocials'
import {Helmet} from 'react-helmet'

const Header = () => {

  const ogData = {
    title: 'Gulrez Alam | Page',
    description: 'Gulrez Alam, a FullStack Developer with experience in React, Node, Express, and MongoDB.',
    image: ME,
    url: 'https://1drv.ms/b/s!Ajy2DojKmI_jg91-8t1wg_K8Y2faow?e=echebW'
  }
  return (
   <header>

      <Helmet>
        <title>{ogData.title}</title>
        <meta name='description' content={ogData.description} />
        <meta property='og:title' content={ogData.title} />
        <meta property='og:description' content={ogData.description} />
        <meta property='og:image' content={ogData.image} />
        <meta property='og:url' content={ogData.url} />
      </Helmet>

     <div className="container header__container">
      <h5>Hello I'm</h5>
      <h1>Gulrez Alam</h1>
      <h5 className='text-light'>FullStack Developer</h5>
      <CTA />
      <HeaderSocial />

      <div className="me">
        <img src={ME} alt="me" />
      </div>

      <a href="#contact"><div className="scroll__down">Scroll Down</div></a>
     </div>
   </header>
  )
}

export default Header;