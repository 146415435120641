import React from 'react';
import { Helmet } from 'react-helmet';
import './portfolio.css';
import IMG9 from '../../assets/fyle2.png';
import IMG8 from '../../assets/music-room.png';
import IMG7 from '../../assets/crm-system.png';
import IMG6 from '../../assets/jamia.png';
import IMG5 from '../../assets/birthday.png';
import IMG4 from '../../assets/calculator.png';
import IMG3 from '../../assets/todo.png';
import IMG10 from '../../assets/onrender.jpeg';
import ME from '../../assets/me.png';

const data = [
  {
    id: 10,
    image: IMG10,
    alt: 'Gulrez Alam_Build your Project',
    title: 'Build your Project',
    github: 'https://github.com/mrgulrez/Auth',
    demo: 'https://gulrez.onrender.com/'
  },
  {
    id: 9,
    image: IMG9,
    alt: 'Gulrez Alam_Fyle Internship Challenge',
    title: 'GitHub User Search',
    github: 'https://github.com/mrgulrez/fyle-internship-challenge-23',
    demo: 'https://fyle2023.netlify.app/'
  },
  {
    id: 8,
    image: IMG8,
    alt: 'Gulrez Alam_Music Room',
    title: 'Party Room',
    github: 'https://github.com/mrgulrez/music-room',
    demo: 'https://github.com/mrgulrez/music-room'
  },
  {
    id: 7,
    image: IMG7,
    alt: 'Gulrez Alam_CRM System',
    title: 'Customer Management System',
    github: 'https://github.com/mrgulrez/crm-system',
    demo: 'https://github.com/mrgulrez/crm-system'
  },
  {
    id: 6,
    image: IMG6,
    alt: 'Gulrez Alam_Jamia Millia Islamia',
    title: 'Fully Functional University🎓 Website',
    github: 'https://github.com/mrgulrez/fet-jmi',
    demo: 'https://jamiamilliaislamia.netlify.app'
  },
  {
    id: 5,
    image: IMG5,
    alt: 'Gulrez Alam_Birthday Wishing App',
    title: 'Happy Birthday wishing! 🎉 App',
    github: 'https://github.com/mrgulrez/birthday-message/tree/master/2',
    demo: 'https://gulrez-birthday.netlify.app/'
  },
  {
    id: 4,
    image: IMG4,
    alt: 'Gulrez Alam_Calculator App',
    title: 'Calculator App',
    github: 'https://github.com/mrgulrez/OIBGRIP',
    demo: 'https://g1-calculator.netlify.app'
  },
  {
    id: 3,
    image: IMG3,
    alt: 'Gulrez Alam_ToDo App',
    title: 'ToDO App',
    github: 'https://github.com/mrgulrez/OIBGRIP2',
    demo: 'https://g1todoapp.netlify.app'
  }
];

const Portfolio = () => {
  const ogData = {
    title: "Gulrez Alam's Portfolio",
    type: 'website',
    url: 'https://gulrez.com',
    image: ME,
    description: 'Explore the portfolio of Gulrez Alam, showcasing projects in web development, software engineering, and more.',
    siteName: "Gulrez Alam's Portfolio Site"
  };

  return (
    <>
      <Helmet>
        <title>{ogData.title}</title>
        <meta name="description" content={ogData.description} />
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:type" content={ogData.type} />
        <meta property="og:site_name" content={ogData.siteName} />
      </Helmet>
      <section id='portfolio'>
        <h5>My Recent Work</h5>
        <h2>Portfolio</h2>
        <div className="container portfolio__container">
          {data.sort((a, b) => b.id - a.id).map(({ id, image, alt, title, github, demo }) => (
            <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={alt} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank' rel='noopener noreferrer'>Github</a>
                <a href={demo} className='btn btn-primary' target='_blank' rel='noopener noreferrer'>Live Demo</a>
              </div>
            </article>
          ))}
        </div>
      </section>
    </>
  );
}

export default Portfolio;
