import React from 'react';
import { Helmet } from 'react-helmet';
import './testimonials.css';
import AVTR1 from '../../assets/Asad.jpg';
import AVTR2 from '../../assets/aqib.jpg';
import AVTR3 from '../../assets/Naju.jpg';
import AVTR4 from '../../assets/download1.jpeg';

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'Asad Jamal',
    review: 'I was so shocked seeing one of my Classmates doing fantabulous in coding. Through his skills he created sites and apps which are still under work and soon will be online available. Well done Gulrez Alam. Best wishes from my side and all the very best for your future. Congratulations in advance for yours future success🏆',
  },
  {
    avatar: AVTR2,
    name: 'Aqib Khan',
    review: 'It is really amazing to see that one of my friend doing great in the field of development. He has created a superb portfolio website which is available on Google search engine. He always inspires me by his skills and hard work. Good going Gulrez Alam. Hope, you achieve great heights in your life. Best of luck for your great future🌟.',
  },
  {
    avatar: AVTR3,
    name: 'Mohd. Najmuddin',
    review: 'When I used YouTube to learn certain programming topics, I usually suffered from doubts, Gulrez always helped me. His problem-solving skills are awesome. I realized that his mentorship was the reason for getting better knowledge in the computer science field. Thanks Gulrez Alam.',
  },
  {
    avatar: AVTR4,
    name: 'Andrew',
    review: 'Gulrez is a great programmer, he understands problems clearly, breaks them down into hypotheses, and proposes solutions in a coherent manner. He understands concepts quickly, or asks the right questions to help make him clear, and doesn’t need to have everything written down in a specifications document.',
  },
];

const Testimonials = () => {
  const ogData = {
    title: "Gulrez Alam - Client Testimonials",
    type: 'website',
    url: 'https://gulrez.com/testimonials',
    image: AVTR1,
    description: 'Read what clients say about Gulrez Alam, a talented software developer and problem solver.',
    siteName: "Gulrez Alam's Testimonials"
  };

  return (
    <>
      <Helmet>
        <title>{ogData.title}</title>
        <meta name="description" content={ogData.description} />
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:type" content={ogData.type} />
        <meta property="og:site_name" content={ogData.siteName} />
      </Helmet>
      <section id='testimonials'>
        <h5>Review from clients</h5>
        <h2>Testimonials</h2>

        <Swiper className='container testimonials__container'
          modules={[Pagination]}
          spaceBetween={40}
          slidesPerView={1}
          pagination={{ clickable: true }}>
          {
            data.map(({ avatar, name, review }, index) => (
              <SwiperSlide key={index} className='testimonial'>
                <div className='client__avatar'>
                  <img src={avatar} alt={`${name}_testimonial`} />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>
                  {review}
                </small>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </section>
    </>
  );
}

export default Testimonials;
