import React, { useState } from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBook } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';
import { AiOutlineMessage } from 'react-icons/ai';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');

  return (
    <nav>
      <a 
        href='/' 
        onClick={() => setActiveNav('#')} 
        className={activeNav === '#' ? 'active' : ''} 
        alt="HomePage" 
        title="Home"
      >
        <AiOutlineHome />
      </a>
      <a 
        href='#about' 
        onClick={() => setActiveNav('#about')} 
        className={activeNav === '#about' ? 'active' : ''} 
        title="About"
      >
        <AiOutlineUser />
      </a>
      <a 
        href='#experience' 
        onClick={() => setActiveNav('#experience')} 
        className={activeNav === '#experience' ? 'active' : ''} 
        title="Experience"
      >
        <BiBook />
      </a>
      <a 
        href='#services' 
        onClick={() => setActiveNav('#services')} 
        className={activeNav === '#services' ? 'active' : ''} 
        title="Services"
      >
        <RiServiceLine />
      </a>
      <a 
        href='#contact' 
        onClick={() => setActiveNav('#contact')} 
        className={activeNav === '#contact' ? 'active' : ''} 
        title="Contact"
      >
        <AiOutlineMessage />
      </a>
    </nav>
  );
};

export default Nav;
